<template>
    <div class="qa flex items-top w-full">
        <div class="filters">
            <div class="form-control mr-12">
                <input type="text" autocomplete="off" v-model="filter" ref="search">
                <i class="fas fa-search" style="top: 1.3rem"></i>
            </div>
            <h2 class="text-2xl font-bold mb-5">Benutzer auswählen:</h2>
            <div class="select-box">
                <div class="select-box__current" tabindex="1">
                    <div class="select-box__value">
                        <input class="select-box__input" type="radio" id="a" value="a" name="Ben" checked/>
                        <p class="select-box__input-text">-</p>
                    </div>
                    <div v-for="(agent, idx) in agentsLabels" :key="agent" class="select-box__value">
                        <input class="select-box__input" v-model="agentId" type="radio" :id="idx + 1" :value="idx + 1" name="Ben"/>
                        <p class="select-box__input-text">{{ agent }}</p>
                    </div>
                    <img class="select-box__icon" src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true"/>
                </div>
                <ul class="select-box__list">
                    <li v-for="(agent, idx) in agentsLabels" :key="agent">
                        <label class="select-box__option" :for="idx + 1" aria-hidden="aria-hidden">{{ agent }}</label>
                    </li>
                </ul>
            </div>
            <button class="btn focus:outline-none hover:bg-blue-400 transition bg-blue-500 text-white text-2xl font-bold py-4 px-8 rounded shadow" @click.stop="showDate = !showDate">Datum auswählen</button>
            <DatePicker :value="dateValue" @input="updateDate" v-click-outside="closeDate" class="datepicker shadow" v-if="showDate"/>
        </div>
        <div v-if="filteredTickets.length && !searchData.length" class="tickets-qa flex-1">
            <router-link v-for="ticket in filteredTickets" :key="ticket.id" :to="`/qa/${ticket.id}`" :class="`w-100 ticket hover:bg-blue-100 transition-colors cursor-pointer flex justify-between ${ticket.viewed ? 'bg-gray-300' : 'bg-white'} shadow relative mb-3 py-4 px-10`">
                <span class="w-2 h-full absolute top-0 left-0`"></span>
                <p class="text-2xl">
                    <span class="max">
                        {{ ticket.id }}
                    </span>
                </p>
                <p class="flex">
                    <span class="date text-2xl text-gray-400">{{ ticket.created_at.split('T')[0] }}</span>
                </p>
            </router-link>
        </div>
        <div v-if="searchData.length && !filteredTickets.length" class="tickets-qa flex-1">
             <router-link v-for="ticket in searchData" :key="ticket.id" :to="`/qa/${ticket.id}`" :class="`w-100 ticket hover:bg-blue-100 transition-colors cursor-pointer flex justify-between ${ticket.viewed ? 'bg-gray-300' : 'bg-white'} shadow relative mb-3 py-4 px-10`">
                <span class="w-2 h-full absolute top-0 left-0`"></span>
                <p class="text-2xl">
                    <span class="max">
                        {{ ticket.id }}
                    </span>
                </p>
                <p class="flex">
                    <span class="date text-2xl text-gray-400">{{ ticket.created_at.split('T')[0] }}</span>
                </p>
            </router-link>
        </div>

        <div class="no-user flex-1" v-if="filter == '' && agentId == ''">
            <h2>Bitte wählen Sie einen Benutzer</h2>
        </div>
        <div class="no-user flex-1" v-if="filter == '' && !filteredTickets.length">
            <h2>Keine Tickets hier! Bitte warten</h2>
        </div>
    </div>
</template>
    

<style lang="scss" scoped>
    @media screen and (max-width: 576px) {
        .no-user h2 {
            font-size: 3rem;
        }
        .qa {
            flex-direction: column;
        }
        .tickets-qa {
            margin-top: 30px;
        }
        .select-box {
            width: 100% !important;
            z-index: 999999;
        }
        .filters button {
            width: 100%;
        }
    }
    .no-user {
        h2 {
            text-align: center;
            margin-top: 5rem;
            font-size: 5rem;
            font-weight: bold;
            color: rgb(202, 202, 202);
        }
    }
    .qa {
        overflow: hidden;
        min-height: 70vh;
    }
    .datepicker {
        position: absolute !important;
        left: 10rem;
        top: 30rem;
    }
    .select-box {
        position: relative;
        display: block;
        width: 15rem;
        margin: 0 auto;
        font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Calibri', 'Arial', sans-serif;
        font-size: 18px;
        color: #60666d;
        margin-right: 30px;
    
    @media (min-width: 768px) {
        width: 70%;
    }
    
    @media (min-width: 992px) {
        width: 50%;
    }
    
    @media (min-width: 1200px) {
        width: 30rem;
    }
    
    &__current {
        position: relative;
        box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
        cursor: pointer;
        outline: none;
        
        &:focus {
        & + .select-box__list {
            opacity: 1;

            // We have to set "animation-name: none;" to make the list visible (read below how it works)

            animation-name: none;
            
            .select-box__option {
            cursor: pointer;
            }
        }
        
        .select-box__icon {
            transform: translateY(-50%) rotate(180deg);
        }
        }
    }
    
    &__icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 20px;
        opacity: 0.3;
        transition: 0.2s ease;
    }
    
    &__value {
        display: flex;
    }
    
    &__input {
        display: none;
        
        &:checked + .select-box__input-text {
        display: block;
        }
    }
    
    &__input-text {
        display: none;
        width: 100%;
        margin: 0;
        padding: 15px;
        background-color: #fff;
    }
    
    &__list {
        position: absolute;
        width: 100%;
        padding: 0;
        list-style: none;
        opacity: 0;
        
        // We need to use animation with delay.
        // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
        // This delay will not be noticed because we set "opacity" to "0".
        // We also use "animation-fill-mode: forwards" to make the list stay hidden.
        
        animation-name: HideList;
        animation-duration: 0.5s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        animation-timing-function: step-start;
        box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    }
    
    &__option {
        display: block;
        padding: 15px;
        background-color: #fff;
        
        &:hover,
        &:focus {
        color: #546c84;
        background-color: #fbfbfb;
        }
    }
    }

    @keyframes HideList {
    from {
        transform: scaleY(1);
    }
    to {
        transform: scaleY(0);
    }
    }
    .btn {
        width: calc(100% - 30px);
        margin-top: 20px;
    }
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
    export default {
        data() {
            return {
                showDate: false,
                dateValue: new Date(),
                agentId: '',
                filter: '',
                searchData: ''
            }
        },
        mounted() {
            this.getAgents();
            this.getAllTickets();
        },
        asyncComputed: {

        },
        computed: {
            ...mapGetters(['agents', 'allTickets']),
            agentsLabels() {
                return this.agents.map(el => el.name);
            },
            filteredTickets() {
                if(this.agentId != '') {
                    this.updateURL();
                }
                let distinct = [];
                const agent = this.agents[this.agentId - 1]?._id;
                let filtered = this.allTickets.filter(el => {
                    const date = el.created_at.split('T')[0];
                    if(el.requester && this.filter != '') {
                        return el.requester.toLowerCase().includes(this.filter.toLowerCase());
                    }
                    if(this.filter == '') {
                        return el.user == agent && date == this.dateValue.toISOString().split('T')[0];
                    }
                });

                if(!filtered.length && this.filter != '') {
                    (async function(vue, filter) {
                        const searchedData = await vue.$axios.get(`tickets/qa-search?filter=${filter}`)
                        vue.searchData = searchedData.data.tickets;
                    })(this, this.filter);
                }

                filtered.forEach(ticket => {
                    if(!distinct.some(el => el.id == ticket.id)) {
                        distinct.push(ticket);
                    }
                });
                const viewedItems = JSON.parse(window.localStorage.getItem('viewedTickets'));
                if(viewedItems) {
                    distinct = distinct.map(el => {
                        if(viewedItems.includes(el.id)) {
                            return {
                                ...el,
                                viewed: true
                            }
                        } else {
                            return el;
                        }
                    })
                }
                return distinct;
            },
        },
        methods: {
            ...mapActions(['getAgents', 'getAllTickets', 'qaSearch']),
            closeDate() {
                this.showDate = false;
            },
            updateURL(date) {
                let qp = new URLSearchParams();
                if(this.agentId !== '') qp.set('agent', this.agentId);
                if(this.dateValue !== '') qp.set('date', this.dateValue);
                if(this.$route.query.agent != this.agentId || date) {
                    this.$router.replace({ name: "Home", query: {agent: this.agentId, date:this.dateValue} })
                }
            },
            updateDate(val) {
                this.dateValue = val;
                this.updateURL(true);
            },
        },
        created() {
            let agent = this.$route.query.agent;
            if(agent) this.agentId = agent;
            let dateValue = this.$route.query.date;
            if(dateValue) this.dateValue = new Date(dateValue);
        },
        watch: {
            filter: function(newVal, oldVal) {
                if(newVal == '') {
                    this.searchData = [];
                }
            }
        }
    }
</script>