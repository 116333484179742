<template>
    <section>
        <div class="users flex" v-if="loggedUser.admin">
            <Register class="max-w-5xl mr-16" />
            <Users class="flex-1 ml-16" />
        </div>
        <!-- =========== -->
        <!-- Agents view -->
        <!-- =========== -->
        <div class="agents flex" v-if="!loggedUser.admin && !loggedUser.qa">
            <Sidebar @input="updateFilter" class="max-w-xl mr-16" />
            <Tickets :openFilter="filter" class="flex-1 ml-16" />
        </div>

        <!-- =========== -->
        <!-- QA view -->
        <!-- =========== -->
        <div class="qa" v-if="loggedUser.qa">
            <QA></QA>
        </div>
    </section>
</template>

<style lang="scss">
    @media screen and (max-width: 576px) {
        .agents, .users {
            flex-direction: column;
        }
        .users {
            &>div {
                margin-right: 0px;
                margin-left: 0px;
            }
            div:last-child {
                margin-top: 15px;
            }
            form .flex {
                flex-direction: column;
                div:nth-child(2) {
                    padding-left: 0px;
                    margin-bottom: 15px;
                }
                div:nth-child(1) {
                    padding-right: 0px;
                }
            }
        }
    }
</style>

<script>
import { mapGetters } from 'vuex';

import Register from '@/components/Register.vue';
import Users from '@/components/Users.vue';
import Sidebar from '@/components/Sidebar.vue';
import Tickets from '@/components/Tickets.vue';
import QA from '@/views/QA.vue';

export default {
    data() {
        return {
            filter: ''
        }
    },
    components: {
        Register,
        Users,
        Sidebar,
        Tickets,
        QA
    },
    methods: {
        updateFilter(val) {
            this.filter = val;
        }
    },
    computed: {
        ...mapGetters(['loggedUser']),
    }
}
</script>